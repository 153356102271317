import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import ServiceInterface from "@/vue/service/service-interface";
import { CONTAINER_TYPES } from "@/vue/infrastructure/ioc/container-types";
import Utils from "@/vue/infrastructure/utils/helpers";
import configUtilities from "@/vue/infrastructure/utils/configUtilities";
import DmBrand from "@/vue/domain/brand/dm-brand";
import DmContent from "@/vue/domain/content/dm-content";

@Component
export default class ApplicationComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  private brandLoaded = false;
  private signboardContentLoaded = false;
  private magazineContentLoaded = false;

  get readyToStart(): boolean {
    return this.brandLoaded && this.signboardContentLoaded && this.magazineContentLoaded;
  }

  beforeMount() {
    this.login();
  }

  private loadBrand() {
    this.service
      .getBrand()
      .then((brandData: DmBrand) => {
        this.$store.dispatch("setBrand", brandData);
        this.brandLoaded = true;
      })
      .catch((error: Error) => {
        Utils.defaultApiErrorHandler(error, this);
      });
  }

  private loadBrandContents(typesCodes: Array<string>, contentAliases: Array<string>, callback: any) {
    this.service
      .getBrandContents({ typesCodes: typesCodes, aliases: contentAliases })
      .then((contents: Array<DmContent>) => {
        callback(contents);
      })
      .catch((error: Error) => {
        Utils.defaultApiErrorHandler(error, this);
      });
  }

  private loadSignboardContent() {
    this.loadBrandContents(configUtilities.BRAND_CONTENT_SIGNBOARD, [this.$route.params.signboardAlias], (contents: Array<DmContent>) => {
      this.$store.dispatch("setSignboardContents", contents);
      this.signboardContentLoaded = true;
      this.loadMagazineContent();
    });
  }

  private loadMagazineContent() {
    this.loadBrandContents(configUtilities.BRAND_CONTENT_MAGAZINE, [this.$route.params.magazineAlias], (contents: Array<DmContent>) => {
      this.$store.dispatch("setMagazineContents", contents);
      this.magazineContentLoaded = true;
    });
  }

  private login() {
    this.service
      .login()
      .then(() => {
        this.loadBrand();
        this.loadSignboardContent();
      })
      .catch((error: Error) => {
        Utils.defaultApiErrorHandler(error, this);
      });
  }
}
